import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';

function Videos() {
  const videosRef = useRef(null);

  const videos = [
    { id: 1, url: 'https://www.youtube.com/embed/ZOYQklpKhS8', title: 'Video 1' },
    { id: 2, url: 'https://www.youtube.com/embed/5NUIMKXM2WA', title: 'Video 2' },
    { id: 3, url: 'https://www.youtube.com/embed/NHWZkQyYYGI', title: 'Video 3' },
  ];

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.from('.video-card', {
        y: 50,
        opacity: 0,
        duration: 0.8,
        stagger: 0.2,
        scrollTrigger: {
          trigger: videosRef.current,
          start: 'top center',
          end: 'bottom center',
          toggleActions: 'play none none reverse'
        }
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <section ref={videosRef} className="py-5">
      <div className="container">
        <h2 className="text-center mb-5">Featured Videos</h2>
        <div className="row g-4">
          {videos.map(video => (
            <div key={video.id} className="col-md-4">
              <div className="video-card card h-100">
                <div className="ratio ratio-16x9">
                  <iframe 
                    src={video.url} 
                    title={video.title}
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="card-body">
                  <h5 className="card-title">{video.title}</h5>
                  <p className="card-text">Comments and questions are welcome</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Videos;