import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './Hero.css'; // We'll create this next
import backgroundVideo from '../assets/mountainRiverB.mp4';

function Hero() {
  const heroRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const buttonRef = useRef(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      // Hero animations
      const tl = gsap.timeline();

      tl.from(titleRef.current, {
        y: 100,
        opacity: 0,
        duration: 1,
        delay: 0.5
      })
          .from(subtitleRef.current, {
            y: 50,
            opacity: 0,
            duration: 1
          }, "-=0.5")
          .from(buttonRef.current, {
            y: 30,
            opacity: 0,
            duration: 0.8
          }, "-=0.5");
    });

    return () => ctx.revert();
  }, []);

  return (
      <section ref={heroRef} className="hero">
        {/* Video Background */}
        <div className="hero-video-wrapper">
          <video
              className="hero-video"
              autoPlay
              loop
              muted
              playsInline
          >
            <source src={backgroundVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="hero-overlay"></div>

            {/* Content */}
            <div className="hero-content">
                <div className="container">
                    <h1 ref={titleRef} className="hero-title">
                        Welcome to Steven's portfolio
                    </h1>
                    <p ref={subtitleRef} className="hero-subtitle">
                        Discover amazing content in web and mobile apps
                    </p>
                    <div ref={buttonRef} className="hero-buttons">
                        <button className="btn btn-primary btn-lg me-3">
                            Get Started
                        </button>
                        <button className="btn btn-outline-light btn-lg">
                            <a href="https://www.linkedin.com/in/stevenalila-ink/">Linkedin</a>
                        </button>
                    </div>
                </div>
            </div>

            {/* Scroll Indicator */}
            <div className="scroll-indicator">
                <div className="scroll-arrow"></div>
                <span>Scroll Down</span>
            </div>

        </div>


      </section>
  );
}

export default Hero;