import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Hero from './components/Hero';
import Carousel from './components/Carousel';
import Videos from './components/Videos';
import Messages from './components/Messages';

function App() {
  return (
    <div className="App">
      <Hero />
      <Carousel />
      <Videos />
      <Messages />
    </div>
  );
}

export default App;
