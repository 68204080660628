import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import financialfitbit from '../assets/financialfitbit.png';
import romamath from '../assets/romamath.png';
import traveldiary from '../assets/traveldiary.png';

function Carousel() {
  const carouselRef = useRef(null);

  const slides = [
    { id: 1, image: financialfitbit, title: 'Slide 1' },
    { id: 2, image: romamath, title: 'Slide 2' },
    { id: 3, image: traveldiary, title: 'Slide 3' },
  ];

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.from('.carousel-item', {
        opacity: 0,
        scale: 0.8,
        duration: 1,
        stagger: 0.2,
        scrollTrigger: {
          trigger: carouselRef.current,
          start: 'top center',
          end: 'bottom center',
          toggleActions: 'play none none reverse'
        }
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <section ref={carouselRef} className="py-5 bg-light">
      <div className="container">
        <h2 className="text-center mb-5">Featured Content</h2>
        <div id="mainCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
            {slides.map((slide, index) => (
              <button key={slide.id} type="button" data-bs-target="#mainCarousel" 
                data-bs-slide-to={index} className={index === 0 ? 'active' : ''}></button>
            ))}
          </div>
          <div className="carousel-inner">
            {slides.map((slide, index) => (
              <div key={slide.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                <img src={slide.image} className="d-block w-100 rounded" alt={slide.title} />
                <div className="carousel-caption">
                  <h5>{slide.title}</h5>
                </div>
              </div>
            ))}
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#mainCarousel" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#mainCarousel" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </section>
  );
}

export default Carousel;